export const locationMaps = {
    Melbourne: "albert_park",
    Austin: "americas",
    Sakhir: "bahrain",
    Baku: "baku",
    Budapest: "hungaroring",
    Imola: "imola",
    "São Paulo": "interlagos",
    Jeddah: "jeddah",
    "Marina Bay": "marina_bay",
    Monaco: "monaco",
    Spielberg: "red_bull_ring",
    "Mexico City": "rodriguez",
    Shanghai: "shanghai",
    Silverstone: "silverstone",
    "Spa-Francorchamps": "spa",
    Suzuka: "suzuka",
    "Las Vegas": "vegas",
    Montréal: "villeneuve",
    Zandvoort: "zandvoort",
    Miami: "miami",
    Monza: "monza",
    Barcelona: "catalunya",
    Lusail: "losail",
    "Yas Island": "yas_marina",
};